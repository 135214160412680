.search-pop-overlay {
  background: rgba(0,0,0,0.3);
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1400;
}
.search-popup {
  background: #fff;
  border-radius: 5px;
  color: #333;
  display: none;
  height: 80%;
  left: 50%;
  margin-left: -350px;
  padding: 0;
  position: fixed;
  top: 15%;
  width: 700px;
  z-index: 1500;
  cursor: url(https://cdn.jsdelivr.net/gh/honjun/cdn@1.6/img/cursor/normal.cur),auto
}
@media (max-width: 767px) {
  .search-popup {
    border-radius: 0;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    top: 50px;
    width: 100%;
  }
}
.search-popup .search-icon,
.search-popup .popup-btn-close {
  color: #999;
  font-size: 18px;
  height: 36px;
  padding-right: 10px;
  width: 18px;
}
.search-popup .popup-btn-close {
  border-left: 1px solid #eee;
  cursor: pointer;
  float: right;
  cursor: url(https://cdn.jsdelivr.net/gh/honjun/cdn@1.6/img/cursor/ayuda.cur),auto
}
.search-popup .popup-btn-close:hover .fa {
  color: #222;
}
.search-popup .search-header {
  background: #f5f5f5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px;
}
.search-loading-icon {
  margin: 20% auto 0 auto;
  text-align: center;
}
.search-popup ul.search-result-list {
  margin: 0 5px;
  padding: 0;
}
.search-popup p.search-result {
  border-bottom: 1px dashed #ccc;
  padding: 5px 0;
}
.search-popup a.search-result-title {
  font-weight: bold;
}
.search-popup .search-keyword {
  border-bottom: 1px dashed #ff2a2a;
  color: #ff2a2a;
  font-weight: bold;
}
.search-popup #search-result {
  height: calc(100% - 55px);
  overflow: auto;
  padding: 5px 25px;
  position: relative;
}
.search-popup .search-input-container {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  width: calc(100% - 90px);
}
.search-popup .search-input-container input {
  background: transparent;
  border: 0;
  display: block;
  height: 20px;
  outline: 0;
  padding: 8px 0;
  vertical-align: middle;
  width: 100%;
  cursor: url(https://cdn.jsdelivr.net/gh/honjun/cdn@1.6/img/cursor/texto.cur),auto
}
.search-popup #no-result {
  color: #ccc;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
